<template>
    <div class="funnel"></div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
          serviceList:[]
        }
    },
    methods: {
        // 手机安全分析
        setChart() {
            let option = {
              title: {
                text: '【服务人次】',
                textStyle: {
                  color: '#75deef',
                  fontSize: 12,
                  fontWeight: 'normal'
                },
                top: '6%',
                left: '40%'
              },
                tooltip: {
                    trigger: 'item',
                    formatter: "{b} : {c}",
                    backgroundColor: '#11367a',
                    textStyle: {
                        color: '#6dd0e3',
                        fontSize: 10,
                    },
                },

                legend: {
                    top: '25%',
                    right: "7%",
                    orient: "vertical",
                    itemWidth: 0,
                    itemGap: 27,
                    textStyle: {
                        color: '#75deef',
                        fontSize: 14
                    },
                  data: ["长沙市",'株洲市','湘潭市','衡阳市',"常德市",'怀化市'],
                },
                series: [
                    {
                        name: '预期',
                        type: 'funnel',
                        left: '6%',
                        width: '60%',
                        height: '75%',
                        label: {

                            normal: {
                                show: false,
                                formatter: '{b}预期',
                                position: 'right'
                            },
                            emphasis: {
                                show: false,
                                position: 'inside',
                                formatter: '{b}预期: {c}%'
                            }
                        },
                        labelLine: {
                            normal: {
                                show: false
                            }
                        },
                        itemStyle: {
                            normal: {
                                borderWidth: 0,
                                opacity: .7,
                                color: function (params) {

                                    var colorList = [
                                        '#2c7afc', '#bd2229', '#a262f2', '#fe672c', '#27fcfe','rgb(241,130,207)'
                                    ];
                                    return colorList[params.dataIndex];
                                }
                            },

                        },
                        data: [
                          {value: this.serviceList[0], name: '长沙市'},
                          {value: this.serviceList[1], name: '株洲市'},
                          {value: this.serviceList[2], name: '湘潭市'},
                          {value: this.serviceList[3], name: '衡阳市'},
                          {value: this.serviceList[4], name: '常德市'},
                          {value: this.serviceList[9], name: '怀化市'},
                        ]
                    },
                    {
                        name: '实际',
                        type: 'funnel',
                        left: '6%',
                        height: '75%',
                        width: '60%',
                        maxSize: '60%',
                        z: 3,
                        label: {
                            normal: {
                                position: 'inside',
                                formatter: '{c}人',
                                textStyle: {
                                    color: '#fff'
                                }
                            },
                            emphasis: {
                                position: 'inside',
                            }
                        },
                        itemStyle: {
                            normal: {
                                opacity: .5,
                                borderWidth: 0,
                                color: function (params) {
                                    var colorList = [
                                        'rgb(44,123,254)', 'rgb(194,35,42)', 'rgb(162,98,242)', 'rgb(254,103,44)', 'rgb(44,252,254)','rgb(241,130,207)'
                                    ];
                                    return colorList[params.dataIndex];
                                }
                            },
                        },
                        data: [
                          {value: this.serviceList[0], name: '长沙市'},
                          {value: this.serviceList[1], name: '株洲市'},
                          {value: this.serviceList[2], name: '湘潭市'},
                          {value: this.serviceList[3], name: '衡阳市'},
                          {value: this.serviceList[4], name: '常德市'},
                          {value: this.serviceList[9], name: '怀化市'},
                        ]
                    }
                ]
            };

            let myChart = this.$echarts(this.$el);

            myChart.clear();
            myChart.resize()
            myChart.setOption(option);
        },
    },
    mounted() {
        this.setChart()
    },
}
</script>

<style lang="less" scoped>
.funnel {
    width: 100%;
    height: 100%;
}
</style>